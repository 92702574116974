import React, {Suspense, useMemo, lazy} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import NotFound from "../not-found/not-found";
import Loading from "../loading/loading";
import useModifier from "../../hooks/use-modifier";
import {detect} from 'detect-browser';

const HomePage = lazy(() => import('../../pages/home'));
const Questionnaire = lazy(() => import('../../pages/questionnaire'));
const HowDidYouScore = lazy(() => import('../../pages/how-did-you-score'));
const Score = lazy(() => import('../../pages/score'));
const DataCollection = lazy(() => import('../../pages/data-collection'));
const Thanks = lazy(() => import('../../pages/thanks'));

export default function App() {
    const {name, version} = useMemo(() => {
        return detect()
    }, [detect]);
    const modifier = useModifier({name: "app", variation: [name, `${name}-${version}`]});

    return (
        <div className={modifier}>
            <Router>
                <Suspense fallback={<Loading/>}>
                    <Switch>
                        <Route path="/" exact component={HomePage}/>
                        <Route path="/questionnaire" exact component={Questionnaire}/>
                        <Route path="/how-did-you-score" exact component={HowDidYouScore}/>
                        <Route path="/score" exact component={Score}/>
                        <Route path="/data-collection" exact component={DataCollection}/>
                        <Route path="/thanks" exact component={Thanks}/>
                        <Route exact component={NotFound}/>
                    </Switch>
                </Suspense>
            </Router>
        </div>
    )
}